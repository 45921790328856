import tw from "twin.macro";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import _ from "lodash";

import { OpaqueCard, StyledButton } from "@components/StyledComponents";
import { HelperTextLabel } from "@features/ui";
import { useVariantOptionsListQuery } from "@features/variantOptions";
import { CldImage } from "@services/cloudinary";
import { SortableTable } from "@services/dndkit";
import {
  ControlledSwitchInput,
  ControlledTextInput,
  externalIdValidation,
  intValidation,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";
import useRoleIs from "@utils/useRoleIs";

import { variantCombinationsFromOptions } from "../../../helpers";
import { FormVariant } from "../../../types";
import { useItemImages } from "../../ItemImageContext";

const Cell = tw(
  TableCell
)`whitespace-nowrap relative bg-white border-neutral-200 border-b`;
const LeftStickyCell = tw(Cell)`
  sticky left-0 z-10 shadow-lg border-r`;
const RightStickyCell = tw(Cell)`
  sticky right-0 z-10 shadow-lg border-l`;

const VariantsTable = ({
  setVariantImageModalSelection,
  selectedVariants,
  setSelectedVariants,
}: {
  setVariantImageModalSelection: Dispatch<SetStateAction<string[]>>;
  selectedVariants: string[];
  setSelectedVariants: Dispatch<SetStateAction<string[]>>;
}) => {
  const [variantSortOrder, setVariantSortOrder] = useState<string[]>([]);
  const { setValue, getValues, unregister, control } = useFormContext();
  const roleIs = useRoleIs();

  const { images } = useItemImages();
  const imagesById = _.keyBy(images, "id");

  const { data: allVariantOptions, isLoading } = useVariantOptionsListQuery();

  const isBundle = useWatch({ name: "isBundle" });
  const variantOptions = useWatch({ name: "variantOptions" });

  const canEditCachedWarehouseQty = roleIs("super");
  const lastCheckedIndex = useRef<number | null>(null);

  const variants: Record<string, FormVariant> = useWatch({ name: "variants" });

  const tableVariants = _(variants)
    .values()
    .filter((v) => !v.isDefault)
    .map((v) => ({ ...v, id: v.hash, entId: v.id })) // the table requires ID to be a unique identifier
    .sortBy((v) => variantSortOrder.indexOf(v.hash))
    .value();

  const allSelected =
    selectedVariants.length >=
    Object.values(variants).filter((v) => !v.isDefault).length;

  const handleToggleAllSelected = () => {
    if (allSelected) {
      setSelectedVariants([]);
    } else {
      setSelectedVariants(
        Object.values(variants)
          .filter((v) => !v.isDefault)
          .map((v) => v.hash)
      );
    }
  };
  const toggleChecked = (e: any, hash: string) => {
    const idx = variantSortOrder.indexOf(hash);
    let hashesToToggle = [hash];

    // Hold shift to select a range of variants
    if (e.nativeEvent.shiftKey && lastCheckedIndex.current !== null) {
      const start = Math.min(lastCheckedIndex.current + 1, idx);
      const end = Math.max(lastCheckedIndex.current, idx + 1);
      const hashesToSelect = variantSortOrder.slice(start, end);
      hashesToToggle = _.xor(selectedVariants, hashesToSelect);
    }

    lastCheckedIndex.current = selectedVariants.includes(hash) ? null : idx;
    setSelectedVariants(_.xor(selectedVariants, hashesToToggle));
  };
  const bulkSetValue = (property: string, value: any) =>
    selectedVariants.forEach((hash) =>
      setValue(`variants.${hash}.${property}`, value, { shouldDirty: true })
    );
  // const setItems = (items) => setValue(variants, items, { shouldDirty: true });
  useEffect(() => {
    if (!allVariantOptions) return;
    // set defaults
    const formVariants: Record<string, FormVariant> = getValues().variants;
    const allVariantCombinations = variantCombinationsFromOptions(
      variantOptions,
      allVariantOptions
    );

    const variantsToRemove = Object.keys(formVariants).filter(
      (hash) =>
        hash && !allVariantCombinations.some((variant) => variant.hash === hash)
    );
    variantsToRemove.forEach((hash) => {
      if (!formVariants[hash].id) {
        unregister(`variants.${hash}`);
      }
    });

    allVariantCombinations.forEach((formVariant) => {
      const variantInitialized = formVariants[formVariant.hash];

      if (!variantInitialized) {
        setValue(`variants.${formVariant.hash}`, formVariant, {
          shouldDirty: true,
          shouldTouch: true, // for some reason, we need this to trigger dirty
        });
      }
    });
    setVariantSortOrder(
      _.sortBy(Object.values(formVariants), "orderPosition").map((v) => v.hash)
    );
  }, [variantOptions, getValues, setValue, allVariantOptions, unregister]);

  const changeItems = (items) => {
    items.forEach((item, index) => {
      setValue(`variants.${item.hash}.orderPosition`, index, {
        shouldDirty: true,
      });
    });
    setVariantSortOrder(items.map((item) => item.hash));
  };

  if (isLoading) return <CircularProgress />;

  return (
    <div tw="space-y-3 -mx-6">
      <SortableTable
        head={() => (
          <TableHead>
            <TableRow>
              <LeftStickyCell>
                <Checkbox
                  checked={allSelected}
                  indeterminate={selectedVariants.length > 0 && !allSelected}
                  onChange={handleToggleAllSelected}
                  size="small"
                  tw="ml-[10px]"
                />
              </LeftStickyCell>
              <Cell>Variant</Cell>
              <Cell>
                <HelperTextLabel title="Setting an upcharge will increase the price on a specific variant. For example, if a 2XL shirt is $15.00 and a Large shirt is $13.00, you would set an upcharge of $2.00 on the 2XL shirt.">
                  Upcharge
                </HelperTextLabel>
              </Cell>
              <Cell>
                <HelperTextLabel title="External IDs are used as inventory numbers for warehouses. If no external ID is given, the SKU is used.">
                  External ID
                </HelperTextLabel>
              </Cell>
              <Cell>
                <HelperTextLabel title="Reorder Threshold">
                  Reorder Threshold
                </HelperTextLabel>
              </Cell>
              {canEditCachedWarehouseQty && isBundle && (
                <Cell>On Hand Qty</Cell>
              )}
              <RightStickyCell>Active</RightStickyCell>
            </TableRow>
          </TableHead>
        )}
        items={tableVariants}
        onChange={changeItems}
        renderItem={(variant, dragging) => {
          const controlled = (name: string, rules?: any) => ({
            name: `variants.${variant.hash}.${name}`,
            control,
            rules,
          });
          return (
            <SortableTable.Item id={variant.hash} className="group">
              <LeftStickyCell>
                <div tw="flex items-center">
                  <Tooltip
                    title={!dragging && "Order Position"}
                    placement="top"
                  >
                    <span>
                      <SortableTable.DragHandle tw="-ml-2 mr-2 text-xl text-neutral-600 group-hover:block" />
                    </span>
                  </Tooltip>
                  <Checkbox
                    size="small"
                    tw="-ml-2"
                    checked={selectedVariants.includes(variant.hash)}
                    onChange={(e) => toggleChecked(e, variant.hash)}
                  />
                  {variant.imageId ? (
                    <div
                      tw="
              rounded w-12 h-12 border-2 border-neutral-200 cursor-pointer
              flex items-center p-1"
                      onClick={() =>
                        setVariantImageModalSelection([variant.hash])
                      }
                    >
                      <CldImage
                        image={imagesById[variant.imageId]}
                        size="thumbnail"
                      />
                    </div>
                  ) : (
                    <div
                      tw="
              rounded w-12 h-12 border-2 border-neutral-300 cursor-pointer border-dashed 
              flex items-center justify-center text-neutral-400"
                      onClick={() =>
                        setVariantImageModalSelection([variant.hash])
                      }
                    >
                      <AddPhotoAlternateOutlined fontSize="small" />
                    </div>
                  )}
                </div>
              </LeftStickyCell>
              <Cell>
                <div>{variant.name}</div>
                {!variant.entId && (
                  <span tw="text-xs text-primary-800 bg-primary-50 px-2 py-0.5 rounded">
                    New
                  </span>
                )}
                {variant.variantSku && (
                  <div tw="text-neutral-400 hover:text-neutral-600 text-xs">
                    {variant.variantSku}
                  </div>
                )}
              </Cell>
              <Cell>
                <ControlledTextInput
                  tw="min-w-[6em]"
                  {...moneyAdornment}
                  {...controlled(`upcharge`, moneyValidation)}
                  onFocus={(e) => e.target.select()}
                />
              </Cell>

              <Cell>
                <ControlledTextInput
                  tw="min-w-[10em]"
                  {...controlled(`externalWarehouseId`, externalIdValidation)}
                  placeholder={variant.variantSku}
                />
              </Cell>
              <Cell>
                <ControlledTextInput
                  {...controlled(`reorderThreshold`)}
                  placeholder="0"
                />
              </Cell>
              {canEditCachedWarehouseQty && isBundle && (
                <Cell>
                  <ControlledTextInput
                    {...controlled(`quantityOnHand`, intValidation)}
                  />
                </Cell>
              )}
              <RightStickyCell>
                <ControlledSwitchInput
                  size="small"
                  {...controlled(`isActive`)}
                />
              </RightStickyCell>
            </SortableTable.Item>
          );
        }}
      />
      {selectedVariants.length > 0 && (
        <OpaqueCard tw="fixed left-1/2 -translate-x-1/2 bottom-8 px-6 z-10 drop-shadow-lg border-2 border-neutral-800">
          <div tw="flex items-baseline gap-4">
            <div tw="text-neutral-700">Bulk Actions</div>
            <StyledButton
              outlined
              size="small"
              onClick={() => setVariantImageModalSelection(selectedVariants)}
            >
              Set Image
            </StyledButton>
            <StyledButton
              outlined
              size="small"
              onClick={() => bulkSetValue("isActive", true)}
            >
              Activate
            </StyledButton>
            <StyledButton
              outlined
              size="small"
              onClick={() => bulkSetValue("isActive", false)}
            >
              Deactivate
            </StyledButton>
          </div>
        </OpaqueCard>
      )}
    </div>
  );
};

export default VariantsTable;
