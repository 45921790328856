import { AxiosError, isAxiosError } from "axios";

import client from "@services/api";
import parseApiErrorMessage from "@services/api/parseApiErrorMessage";
import asyncPool from "@utility/asyncPool";

import { variantOptionIdsFromHash } from "../helpers";
import { FormVariant } from "../types";

const handleVariantError =
  (variantSku: string | undefined, warehouseId?: string) =>
  (error: Error | AxiosError) => {
    if (!isAxiosError(error)) throw error;
    const errMessage = parseApiErrorMessage(error);

    const message = [
      variantSku,
      errMessage.startsWith("External warehouse")
        ? `The External ID ${warehouseId} is already in use.`
        : errMessage,
    ]
      .filter((x) => !!x)
      .join(": ");
    throw new Error(message);
  };

const saveItemVariants = async (
  itemId: string,
  variantFormData: Record<string, FormVariant>
) => {
  const res = await asyncPool(
    5,
    Object.entries(variantFormData),
    ([hash, { id, ...variant }]) => {
      const requestBody = {
        ...variant,
        itemId,
        variantOptions: variantOptionIdsFromHash(hash).map((id) => ({
          id,
        })),
        image: variant.imageId && {
          id: variant.imageId,
        },
        relationshipNames: ["variantOptions", "image"],
      };

      if (id) {
        return client
          .update(`variants/${id}`, requestBody)
          .catch(
            handleVariantError(variant.variantSku, variant.externalWarehouseId)
          );
      } else {
        return client
          .post(`variants`, requestBody)
          .catch(
            handleVariantError(variant.variantSku, variant.externalWarehouseId)
          );
      }
    }
  );
  if (res.errors) {
    throw new Error(res.errors.map((e) => e.message).join("\n"));
  }
  return res.results;
};
export default saveItemVariants;
